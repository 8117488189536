<template>
  <div>
    <div class="radio-item">
      <input
        :id="slugify(radioGroupId + label)"
        v-bind="$attrs"
        v-model="handleInput"
        type="radio"
        :class="['input', errorClass]"
        :data-testid="`${formatNameForTestid(name)}_radio_button`"
        :name="name"
        :autocomplete="name"
      />
      <label
        v-if="label"
        :class="['radio-item__label', `radio-item__label--${size}`]"
        :for="slugify(radioGroupId + label)"
      >
        {{ label }}
        <span v-if="required" class="required"> *</span>
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { slugify } from '@/utils/helpers'
import { formatNameForTestid } from '@/utils/forms'

defineOptions({
  inheritAttrs: false,
})

interface Props {
  modelValue: string | number
  label?: string
  required?: boolean
  name?: string
  radioGroupId: string
  size?: 's' | 'm'
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string | number): void
}>()
const { handleInput, errorClass } = useInput(props, emit)
</script>
<style lang="scss" scoped>
.input {
  width: 100%;
}
.radio-item {
  display: flex;
  align-items: top;
  input {
    flex: 0;
    position: relative;
    top: 5px;
  }

  &__label {
    font-size: var(--font-size-paragraph-m);
    line-height: 1.65;
    padding-left: 12px;
    margin-bottom: 0px;
    text-align: left;

    &--s {
      font-size: var(--font-size-paragraph-s);
      position: relative;
      top: 2px;
      padding-left: 14px;
    }
  }
}
</style>
